<template>
  <div>
    <h1 v-if="type === 'h1'">
      <slot />
    </h1>

    <h3 v-else-if="type === 'h3'">
      <slot />
    </h3>

    <h2 v-else>
      <slot />
    </h2>
  </div>
</template>

<script>
export default {
    props: {
        type: {
            default: 'h2',
            type: String,
        },
        align: {
            default: 'left',
            type: String,
        },
    },
};

</script>
