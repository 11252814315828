<template>
  <div
    class="auth-wrapper auth-v1 px-2"
  >
    <div class="auth-inner py-2">
      <b-card
        style="border-radius: 16px"
      >
        <b-link class="brand-logo">
          <logo style="width: 300px" />
        </b-link>

        <Heading> Esqueceu sua senha? </Heading>
        <p> Introduza o seu e-mail e enviaremos instruções para redefinir a sua senha.  </p>

        <validation-observer
          ref="loginValidation"
          #default="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                  class="input-color"
                  autofocus
                  @focus="error = ''"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- submit button -->
            <div
              v-if="error"
              class="text-danger text-center mb-1 mt-1"
            >
              {{ error }}
            </div>

            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="validationForm"
            >
              Enviar
            </b-button>
          </b-form>

          <b-card-text class="text-center mt-2 d-block">
            <b-link :to="{ name: 'login' }">
              <span>Voltar</span>
            </b-link>
          </b-card-text>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';
import {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BInputGroup,
    BInputGroupAppend,
} from 'bootstrap-vue';
import '@validations';
import {
    togglePasswordVisibility,
} from '@core/mixins/ui/forms';

import Logo from '@core/layouts/components/Logo.vue';

import AuthService from '@/services/osiris/AuthService';
import Heading from '@/views/components/Heading.vue';

export default {
    components: {
        Logo,
        BButton,
        BForm,
        BFormInput,
        BFormGroup,
        BCard,
        BLink,
        BInputGroup,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        Heading,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            userEmail: '',
            password: '',
            status: '',
            isLogged: false,
            error: '',
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        },
    },
    methods: {
        async validationForm() {
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    this.login();
                }
            });
        },

        async login() {
            this.isLoading(true);
            const { userEmail: email } = this;
            const { status, data } = await AuthService.forgotPassword({ email });

            this.error = status !== 200 || status !== 201 ? 'Esse e-mail não pertence a uma conta válida' : '';

            this.isLoading(false);
            if (status === 200) {
                this.modalSuccess('Um email foi enviado com as instruções para troca de senha, verifique sua caixa de entrada com as instruções');
                this.$router.push('/login');
            }
        },
    },
};
</script>

  <style scoped lang="scss">
    @import "@core/scss/vue/pages/page-auth.scss";
  </style>
